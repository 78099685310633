import { getUrl } from './base-url';
import type { WebPage } from 'schema-dts';

export function webpage(pathname: string, opts: { title: string; description?: string; hero?: string }): WebPage {
	return {
		'@type': 'WebPage',
		'@id': getUrl(pathname, 'webpage'),
		name: opts.title,
		url: getUrl(pathname),
		description: opts.description ?? 'Book tee times at your favorite courses with Troon.',
	};
}
