import type { ImageObject } from 'schema-dts';

export function image(
	imageUrl: string,
	size: [number, number] = [1280, 800],
	opts: { caption?: string } = {},
): ImageObject {
	const url = new URL(imageUrl);
	url.searchParams.set('width', `${size[0]}`);
	url.searchParams.set('height', `${size[1]}`);
	url.searchParams.set('mode', 'crop');

	const thumbnail = new URL(imageUrl);
	thumbnail.searchParams.set('width', '256');
	thumbnail.searchParams.set('height', '256');
	thumbnail.searchParams.set('mode', 'crop');

	return {
		'@type': 'ImageObject',
		'@id': url.toString(),
		url: url.toString(),
		width: `${size[0]}`,
		height: `${size[1]}`,
		...opts,
		thumbnail: { '@type': 'ImageObject', url: thumbnail.toString() },
	};
}
